import { RouteLocation } from 'vue-router'
import { APP_NAME } from './config'
import useUserStore from '@stores/user'
import ppClient from './power-pages/pp-client'
import i18n from '@utils/i18n'


function setPageTitle(to: RouteLocation) {
    const nearestWithTitle = to.matched.slice().reverse().find(r => r.name)
    // @ts-ignore
    document.title = APP_NAME + (nearestWithTitle ? ' - ' + i18n.global.t('routes.' + nearestWithTitle.name?.toString() + '.title') : '')
}


function setBodyId(to: any) {
    document.body.id = 'route_' + to.name;
}

async function globalGuard(to: RouteLocation, from: RouteLocation) {
    if (!to.meta.public) {
        const userStore = useUserStore()
        if (!userStore.isLoggedIn) {
           ppClient.redirectToLogin()
           return false
        }
        if (!userStore.csrf) {
            await userStore.getCrsf()
        }
    }
    setPageTitle(to);
    setBodyId(to);
}

async function marqueSelectedGuard(to: RouteLocation, from: RouteLocation) {
    const userStore = useUserStore()
    if (to.meta.public) return
    if (!userStore.marque) return
    // if (userStore.isMultiMarque) return { name: 'marque-selection' }
    // userStore.setSelectedMarque(userStore.accounts[0].accountid)
}

export {
    marqueSelectedGuard,
    globalGuard,
}
