import axios from 'axios'
import mockClient from '@utils/mocks/mocks'
import useUserStore from '@stores/user'
import { startLoading, stopLoading, stopLoadingWithError } from './interceptors'


const client = axios.create({
    // baseURL: PP_URL + '/_api',
    baseURL: '/_api',
    headers: {
        'Accept': 'application/json',
        'Content-Type': 'application/json; charset=utf-8',
        'OData-MaxVersion': '4.0', 'OData-Version': '4.0',
    },
    withCredentials: true,
    validateStatus: (status) => status >= 200 && status < 500
})


if (import.meta.env.DEV) mockClient(client)
const LOG_REQUESTS = false


// Loading


client.interceptors.request.use(startLoading, stopLoadingWithError)
client.interceptors.response.use(stopLoading, stopLoadingWithError)


// Non get queries CSRF


client.interceptors.request.use(config => {
    const controller = new AbortController()
    if (config.method === 'get') return config
    const userStore = useUserStore()
    if (!userStore.csrf) controller.abort()
    config.headers['__RequestVerificationToken'] = userStore.csrf
    config.headers['X-Requested-With'] = 'XMLHttpRequest'
    return config;
});


// Login redirection on auth fail


client.interceptors.response.use((response: any) => {
    if (response.status === 401 || response.status === 403) {
        if (import.meta.env.DEV) return console.warn('Redirect to login')
        // TODO make sure the error is related to an invalid token
        // (it can also be related to permissions for example)
        window.location.href = '/SignIn?ReturnUrl=' + encodeURI('/')
    }
    return response
});


// Request logger


if (import.meta.env.DEV && LOG_REQUESTS) {
    function buildQueryString(params: Record<string, any>) {
        const queryString = Object.keys(params)
            .map(key => `${key}=${params[key]}`)
            .join('&');
    
        return queryString;
    }
    client.interceptors.request.use(config => {
        console.log((config.baseURL || '') + config.url + '?' + buildQueryString(config.params));
        return config;
    });
}


export default client
