import * as VueRouter from 'vue-router'
import { marqueSelectedGuard, globalGuard } from './guards'
import Error404 from '@components/layout/Error404.vue'
import Navigation from '@components/layout/Navigation.vue'


const routes = [
    {
        path: '/bienvenue',
        name: 'public',
        component: () => import('@components/Public.vue'),
        meta: { public: true },
    },
    {
        path: '/',
        component: Navigation,
        beforeEnter: [marqueSelectedGuard],
        children: [
            {
                path: '',
                name: 'home',
                component: () => import('@components/Home.vue'),
                meta: { },
            },
            {
                path: '/commandes',
                component: () => import('@components/orders/Orders.vue'),
                children: [
                    {
                        path: '',
                        name: 'orders',
                        component: () => import('@components/orders/List.vue'),
                        meta: { menu: true },
                    },
                    {
                        path: ':id',
                        name: 'order',
                        component: () => import('@components/orders/Order.vue'),
                        props: true,
                        meta: { },
                    },
                    {
                        path: 'creer',
                        name: 'place-order',
                        component: () => import('@components/orders/place/Place.vue'),
                        meta: { },
                    },
                ]
            },
            {
                path: '/catalogue',
                name: 'catalog',
                component: () => import('@components/catalog/Catalog.vue'),
                meta: { menu: true },
            },
            {
                path: '/tableau-de-bord',
                name: 'dashboard',
                component: () => import('@components/dashboard/Dashboard.vue'),
                meta: { menu: true },
            },
            {
                path: '/documents',
                name: 'documents',
                component: () => import('@components/documents/Documents.vue'),
                meta: { menu: true },
            },
            {
                path: '/profil',
                name: 'profile',
                component: () => import('@components/profile/Profile.vue'),
                meta: { },
            }
        ]
    },
    {
        path: '/:pathMatch(.*)*',
        name: '404',
        component: Error404,
        meta: { public: true },
    },
]


const router = VueRouter.createRouter({
    history: VueRouter.createWebHashHistory(),
    routes,
    scrollBehavior(to, from, savedPosition) {
        return savedPosition || { top: 0 };
    },
});


router.beforeEach(globalGuard);


export default router
