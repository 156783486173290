import { createApp } from 'vue'
import { createPinia } from 'pinia'
import dayjs from 'dayjs'
import 'dayjs/locale/fr'
import router from '@utils/router'
import i18n from '@utils/i18n'
import App from './App.vue'
import './style/index.scss'

dayjs.locale(i18n.global.locale.value)

const pinia = createPinia()
const app = createApp(App)
app.use(i18n)
app.use(router)
app.use(pinia)

app.mount('#app')
