import ppClient from '@utils/pp-fetch'
import router from '@utils/router'


export default {
    csrf: async () => {
        return await ppClient.get('/_layout/tokenhtml').then(r => r.data)
    },
    redirectToLogin() {
        if (import.meta.env.DEV) return router.push({ name: 'public' })
        window.location.href = '/SignIn?ReturnUrl=' + encodeURIComponent('/')
    },
    redirectToLogout() {
        if (import.meta.env.DEV) {
            localStorage.removeItem('u-order-mockup-login')
            return router.push({ name: 'public' })
        } 
        window.location.href = '/Account/Login/LogOff?ReturnUrl=' + encodeURIComponent('/#/bienvenue')
    },
}
