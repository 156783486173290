<style lang="scss">
#menu {
    display: flex;
    justify-content: space-between;
    margin: 40px 0;
    .menu-item {
        padding: 15px;
        white-space: nowrap;
        border: 1px solid transparent;
        &.router-link-exact-active {
            border-color: gray;
        }
    }
}
@media screen and (max-width: 700px) {
    #menu {
        flex-direction: column;
    }
}
</style>

<template>
    <div id="menu">
        <router-link class="menu-item" v-for="route in routes" :key="route.path" :to="route">
            {{ t('routes.' + routeName(route) + '.title') }}
        </router-link>
    </div>
</template>

<script setup lang="ts">
import { useI18n } from 'vue-i18n'
import { RouteRecord, useRouter } from 'vue-router'

const { t } = useI18n()
const router = useRouter()
const routes = router.getRoutes().filter(route => route.meta.menu)
const routeName = (route: RouteRecord) => route.name?.toString() || ''
</script>
