import axios from 'axios'
import mockClient from '@utils/mocks/pp-mocks'
import { startLoading, stopLoading, stopLoadingWithError } from './interceptors'


const client = axios.create({
    headers: {
        'Accept': 'application/json',
		'Content-Type': 'application/json; charset=utf-8',
    },
    withCredentials: true,
})


// Loading


client.interceptors.request.use(startLoading, stopLoadingWithError)
client.interceptors.response.use(stopLoading, stopLoadingWithError)


if (import.meta.env.DEV) mockClient(client)


export default client
