import useLoadingStore from '@stores/loading'
import { AxiosResponse, InternalAxiosRequestConfig } from 'axios'


export function startLoading(config: InternalAxiosRequestConfig) {
    useLoadingStore().requestStart()
    return config
}

export function stopLoading(response: AxiosResponse) {
    useLoadingStore().requestStop()
    return response
}

export function stopLoadingWithError(error: any) {
    useLoadingStore().requestStop()
    return Promise.reject(error)
}
