import { watch } from 'vue'
import { createI18n } from 'vue-i18n'
import fr from './messages.fr.yml'
import en from './messages.en.yml'
import { LS_LOCALE } from '@utils/config'

function getInitialLocale() {
    return localStorage.getItem(LS_LOCALE) || navigator.language.split('-')[0]
}

const i18n = createI18n({
    legacy: false,
    locale: getInitialLocale(),
    fallbackLocale: 'en',
    messages: {
        fr,
        en,
    },
})

watch(i18n.global.locale, (newLocale) => {
    localStorage.setItem(LS_LOCALE, newLocale)
})

export default i18n
