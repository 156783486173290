<style lang="scss">
#header {
    display: flex;
    justify-content: space-between;
    align-items: center;

    #logo {
        text-decoration: none;
        font-size: 2em;
        cursor: pointer;
        color: inherit;
    }

    #userbox {
        text-align: right;

        >* {
            display: block;
            padding: 5px;
        }
    }
}

#footer {
    margin-top: 50px;
    text-align: right;
}
</style>


<template>
    <div id="header">
        <router-link id="logo" :to="{ name: 'home' }">Uriage</router-link>
        <div><span v-if="loadingStore.loading">{{ t('navigation.loading') }}...</span></div>
        <div id="userbox">
            <router-link :to="{ name: 'profile' }">
                {{ contact.fullname }}<br />
                {{ userStore.account?.name }}
            </router-link>
            <a href="#" @click.prevent="logout">{{ t('navigation.logout') }}</a>
        </div>
    </div>
    <Menu />
    <div id="container">
        <router-view v-slot="{ Component }">
            <keep-alive>
                <component :is="Component" />
            </keep-alive>
        </router-view>
    </div>
    <div id="footer">
        <button class="btn btn-primary" @click="postDoc">Post</button>
<!--        <button class="btn btn-primary" @click="showNotification('Titre', 'Descr')">Notif</button> -->
        {{ buildDate }}
    </div>
</template>


<script setup lang="ts">
import { ComputedRef } from 'vue'
import { computed } from 'vue'
import { useI18n } from 'vue-i18n'
import client from '@utils/fetch'
import useUserStore from '@stores/user'
import useLoadingStore from '@stores/loading'
import { buildDateString } from '@utils/index-constants'
import Menu from './Menu.vue'
// import { Ref } from 'vue'
// import { ref } from 'vue'

const { t } = useI18n()
const userStore = useUserStore()
const loadingStore = useLoadingStore()
const contact: ComputedRef = computed(() => userStore.contact)
const buildDate: ComputedRef = computed(() => buildDateString)

function logout() {
    userStore.logout()
}

// const hasNotifPermission: Ref<boolean> = ref(false)

// function checkNotifPermission() {
//     if (!('Notification' in window)) {
//         alert('This browser does not support desktop notification');
//     } else if (Notification.permission === 'granted') {
//         hasNotifPermission.value = true;
//     } else if (Notification.permission !== 'denied') {
//         Notification.requestPermission().then(permission => {
//             hasNotifPermission.value = permission === 'granted';
//         });
//     }
// }

// function showNotification(title: string, message: string) {
//     if (hasNotifPermission.value) {
//         new Notification(title, { body: message });
//     }
// }

// checkNotifPermission()

function postDoc() {
    client.post('/cloudflow/v1.0/trigger/ac222cfc-d82b-1485-b2a9-80d154468662',
        { eventData: JSON.stringify({ accountid: userStore.accountId }) }
    )
}
</script>
