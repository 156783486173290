import { defineStore } from 'pinia'


interface State {
    count: number,
}


export default defineStore('loading', {
    state: (): State => ({
        count: 0,
    }),
    getters: {
        loading: (state) => state.count > 0
    },
    actions: {
        requestStart() {
            this.count++
        },
        requestStop() {
            this.count--
        },
    }
})
