import { computed, ref } from 'vue'
import type { ComputedRef, Ref } from 'vue'
import { defineStore } from 'pinia'
import ppClient from '@utils/power-pages/pp-client'
import { Account } from '@utils/power-pages/types/user'
import { userData } from '@utils/index-constants'
import { LS_CSRF } from '@utils/config'

function decodeHtml(html: string) {
    var txt = document.createElement("textarea");
    txt.innerHTML = html;
    return txt.value;
}
export default defineStore('user', () => {
    const contact: Ref = ref({ contactid: userData.contactid, fullname: decodeHtml(userData.fullname) })
    const account: Ref<Account|null> = ref(userData.account)
    const csrf = ref(localStorage.getItem(LS_CSRF) || '')
    const marque: Ref<string> = ref('Uriage')

    const isLoggedIn:ComputedRef<boolean> = computed(() => !!userData.contactid)
    const accountId:ComputedRef<string> = computed(() => userData.account.accountid)

    async function getCrsf() {
        const rcsrf = await ppClient.csrf()
        csrf.value = rcsrf.substring(rcsrf.indexOf('value="') + 7, rcsrf.lastIndexOf('"'))
        localStorage.setItem(LS_CSRF, (csrf.value || '').toString())
    }

    function setSelectedMarque(smarque: string) {
        marque.value = smarque
        // localStorage.setItem(LS_MARQUE, smarque)
    }

    function logout() {
        localStorage.removeItem(LS_CSRF)
        contact.value = {}
        account.value = null
        csrf.value = ''
        ppClient.redirectToLogout()
    }

    return {
        contact,
        account,
        csrf,
        isLoggedIn,
        accountId,
        marque,
        logout,
        getCrsf,
        setSelectedMarque,
    }
})
